.headshot {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: center;
}

.name {
  margin: 10px 0px 50px 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
}

.about-description {
  text-align: justify;
  display: flex;
  justify-content: center;
  padding: 40px 100px 0px 100px;
  /* background-color: #eeeeee; */
  border-top: solid 1px rgba(0,0,0,.1);
  /* border-bottom: solid 1px rgba(0,0,0,.1); */
  /* margin-bottom: 70px; */
}

.team-header {
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.about-us {
  margin-top: 40px;
  padding-top: 30px;
  border-top: solid 1px rgba(0,0,0,.1);
  /* background-color: #ffffff; */
}

@media only screen and (max-width: 768px) {
  .about-description {
    padding: 40px 20px 20px 20px;
  }
}