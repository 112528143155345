.course-page-wrapper {
  width: 768px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.navbar-toggler-white-icon {
  background-color: #1565c0;
  background-image: url('../../../public/toggler_icon_white.svg');
  width: 40px;
  height: 40px;
  border: none;
}

.course-tabs {
  color: #fff;
  font-size: 12px;
  margin-right: 3px;
  margin-left: 3px;
  text-align: center;
  padding-bottom: 0px;
}

.course-tab-img {
  width: 30px;
  height: 15px;
  margin-bottom: 5px;
}

.nav-dropdown-group {
  width: 100%;
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  padding: 0px;
  text-transform: none;
}
.nav-dropdown-group:hover {
  color: lightgray;
  text-decoration: none;
  text-transform: none;
}